import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import { createApp } from "vue";
import App from "./App.vue";
import env from './config.js';
import router from "./router";
import store from "./store";
import i18n from './lang';
import axios from './helpers/interceptors';
import set from 'lodash/set';
import mask from "vue-the-mask";

import { cleanObject, formatToDate, setNextActive, insertAfter, } from "@/helpers/helpers.js"

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'


const app = createApp(App);

//localComponents
import Table from './components/Table.vue';
import FormElement from './components/FormElement.vue';
import Modal from './components/Modal.vue';

app.component('Table', Table);
app.component('FormElement', FormElement);
app.component('Modal', Modal);
app.component('VueDatePicker', VueDatePicker);

app.config.globalProperties = {
    $set: set,
    $env: env,
    $axios: axios,
    $cleanObject: cleanObject,
    $formatToDate:formatToDate,
    $setNextActive:setNextActive,
    $insertAfter:insertAfter,
};

app.use(i18n);
app.use(mask);
app.use(router);
app.use(store);
app.mount('#app');

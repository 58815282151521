const env = {
    integration:{
        API_BASE_URL:"https://admin.viessmann.com.tr/"
    },
    staging:{
        API_BASE_URL:"https://admin.viessmann.com.tr/"
    },
    production:{
        API_BASE_URL:"https://admin.viessmann.com.tr/"
    }
}


export default env.production
function cleanObject(obj) {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            if (
                obj[key] === null ||
                obj[key] === "" ||
                (Array.isArray(obj[key]) && obj[key].length === 0) ||
                (typeof obj[key] === "object" &&
                    Object.keys(obj[key]).length === 0) ||
                typeof obj[key] === "undefined"
            ) {
                delete obj[key];
            } else if (typeof obj[key] === "object") {
                // Recursive call for nested objects
                cleanObject(obj[key]);

                // Check if the object is empty after cleaning
                if (Object.keys(obj[key]).length === 0) {
                    delete obj[key];
                }
            }
        }
    }

    // Check if the object itself is empty after cleaning
    if (Object.keys(obj).length === 0) {
        return null; // Return null if the object is empty
    } else {
        return obj; // Otherwise, return the cleaned object
    }

}

function formatToDate(date) {
    date = date.toString();
    date = date.replaceAll("-", "")
    const year = date.substring(0, 4);
    const month = date.substring(4, 6);
    const day = date.substring(6, 8);

    return new Date(`${year}/${month}/${day}`).toLocaleDateString("tr-TR");
}

function setNextActive(event) {
    const closestCard = event.target.closest(".page-card");

    const cards = document.querySelectorAll(".page-card");
    cards.forEach((card) => card.classList.add("hide"));

    if (!closestCard.classList.contains("hide")) {
        closestCard.classList.add("hide");
    }

    if (closestCard) {
        const siblings = closestCard.parentNode.children;
        let foundCurrent = false;

        for (let i = 0; i < siblings.length; i++) {
            const sibling = siblings[i];

            if (foundCurrent && sibling.classList.contains("page-card")) {
                sibling.classList.remove("hide");
                break;
            }

            if (sibling === closestCard) {
                foundCurrent = true;
            }
        }
    }

}

function insertAfter(newNode, existingNode) {
    existingNode.parentNode.insertBefore(newNode, existingNode.nextSibling);
}



export { cleanObject, formatToDate, setNextActive, insertAfter }
<template>
  <router-view />
</template>
<script>

export default {
  name: 'App',
  components: {
  }
}
</script>
<style lang='scss'>
@import './assets/sass/app.scss';
</style>
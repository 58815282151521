<template>
    <div class="modal-container">
        <div class="modal-content" :class="className">
            <div class="modal-header">
                <h5 class="h5 mb_-16">{{ title }}</h5>
                <button class="btn btn-empty-primary btn-close" type="button" @click="closeModal" v-if="close"></button>
            </div>
            <div class="modal-body">
                <slot name="body"></slot>
            </div>
            <div class="modal-footer">
                <slot name="footer"></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    emits:["closeModal"],
    props: {
        title:String,
        className:String,
        close:{
            type:Boolean,
            default:true
        }
    },
    methods:{
        closeModal(){
            this.$emit("closeModal", false)
        }
    }
}
</script>
import { createRouter, createWebHistory } from "vue-router";
import axios from "../helpers/interceptors"
import env from "../config"

const routes = [
  {
    path: '/typography',
    component: () => import(/* webpackChunkName: "typography" */ "../pages/Typography.vue"),
  },
  {
    path: '/',
    redirect: 'call-analysis',
    component: () => import(/* webpackChunkName: "layout" */ "../components/Layout.vue"),
    children: [
      {
        path: 'call-analysis',
        component: () => import(/* webpackChunkName: "calls" */ "../pages/call-center/CallAnalysis.vue"),
      },
      {
        path: 'calls',
        component: () => import(/* webpackChunkName: "calls" */ "../pages/call-center/Calls.vue"),
      },
      {
        path: 'call',
        component: () => import(/* webpackChunkName: "call" */ "../pages/call-center/Call.vue"),
      },
      {
        path: 'calls/:id',
        component: () => import(/* webpackChunkName: "call-detail" */ "../pages/call-center/CallDetail.vue"),
      }
    ]
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});


router.beforeEach(async (to, from, next) => {
  try {
    await axios.get("api/whoami");
    next();
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // Eğer 401 hatası alınırsa, yeniden yönlendirme yapılabilir
      window.location.href = env.API_BASE_URL + "oidc/authenticate";
    } else {
      // Diğer hatalar için, normal devam işlemi
      next();
    }
  }
});

export default router;

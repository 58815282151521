import { createI18n } from 'vue-i18n';

const messages = {
    en: {
        continue:"Continue",
        select:"Select",
        call_center_crm:"Call Center CRM",
        call_analysis:"Call Analysis",
        past_calls:"Past Calls",
        new_calls:"New Calls",
        go_next_step:"Go to next step",
        call_result:"Call Result",
        inquire_and_continue:"Inquire and Continue",
        skip_past_logs_and_continue:"Skip Past Logs and Continue",
        go_to_call_result_secreen:"Go to Call Result Screen",
        create_startup_request:"Create Startup Request",
        create_support_request:"Create Support Request",
        end_process:"Cancel Process",
        give_up:"Give Up",
        create_request:"Create Request",
        restart_inquire:"Restart Inquire",
        add:"Add",
        save_information:"Save Information",
        start_new_call:"Start New Call",
        save_call:"Save Call",
        give_up_canceling:"Give Up Canceling",
        close:"Close",
    },
    tr: {
        continue:"Devam Et",
        select:"Seçiniz",
        call_center_crm:"Call Center CRM",
        call_analysis:"Çağrı Analizi",
        past_calls:"Geçmiş Çağrılar",
        new_calls:"Yeni Çağrı",
        call_center_crm:"Call Center CRM",
        go_next_step:"Bir sonraki adıma geç",
        call_result:"Çağrı Sonucu",
        inquire_and_continue:"Sorgula ve Devam et",
        confirm_and_continue:"Onayla ve Devam et",
        skip_past_logs_and_continue:"Geçmiş Kayıtları Atla ve Devam Et",
        go_to_call_result_secreen:"Çağrı Sonuç Ekranına Git",
        create_startup_request:"Devreye Alma Talebi Oluştur",
        create_support_request:"Destek Talebi Oluştur",
        end_process:"İşlemi İptal Et",
        give_up:"Vazgeç",
        create_request:"Talep Oluştur",
        restart_inquire:"Yeniden Sorgula",
        add:"Ekle",
        save_information:"Bilgileri Kaydet",
        start_new_call:"Yeni Sorgu Başlat",
        save_call:"Çağrıyı Kaydet",
        give_up_canceling:"İptal Etmekten Vazgeç",
        close:"Kapat",
    },
};

const i18n = createI18n({
    locale: 'tr',
    messages,
});

export default i18n
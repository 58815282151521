<template>
  <div
    class="form-group form-icon left search"
    :class="minw_auto ? 'minw-auto' : ''"
  >
    <img src="@/assets/images/search.svg" alt="" />
    <input
      type="text"
      placeholder="Arama Yap"
      class="form-control"
      v-model="searchText"
      @input="onSearchInput"
    />
  </div>
</template>
<script>
export default {
    data(){
        return{
            searchText:""
        }
    },
    props:{
        minw_auto:String,
        searchKey:String
    },
    methods:{
        onSearchInput() {
            this.$emit("search", {
                value:this.searchText,
                key:this.searchKey
            });
        },
    }
}
</script>
<template>
  <div class="top-table">
    <button class="btn btn-bordered-secondary-500 bg-transparent" v-if="export">
      <span class="material-symbols-outlined right"> sim_card_download </span>
      Dışa Aktar
    </button>
    <div class="d-flex">
      <slot name="button"></slot>
    </div>
  </div>
  <div class="table-wrapper">
    <div class="table-header" v-if="!nohead">
      <h5 class="table-title h5">{{ title }}</h5>
      <div class="table-header-middle">
        <slot name="table_header_middle"></slot>
      </div>
      <div class="show-row">
        <div class="form-group">
          <label for="show-row">Göster</label>
          <select class="form-select" v-model="pageSize">
            <option :value="count" v-for="count in show_count" :key="count">
              {{ count }} Satır
            </option>
          </select>
        </div>
      </div>
      <!-- <div class="scroll-table" v-if="hide_scroll">
        <button
          class="btn btn-empty"
          type="button"
          @click="scrollTable('left')"
        >
          <span class="material-symbols-outlined"> chevron_left </span>
        </button>
        Yana Kaydır
        <button
          class="btn btn-empty"
          type="button"
          @click="scrollTable('right')"
        >
          <span class="material-symbols-outlined"> chevron_right </span>
        </button>
      </div> -->
    </div>
    <div class="table-header p-0" v-if="document">
      <div class="form-group form-icon left search">
        <img src="@/assets/images/search.svg" alt="" />
        <input type="text" placeholder="Döküman ara" class="form-control" />
      </div>
    </div>
    <div class="table-container">
      <table class="table">
        <thead v-if="!nohead">
          <tr>
            <th
              v-for="th in thead"
              :key="th"
              class="row-title"
              :class="th.minw_auto ? 'minw-auto' : ''"
              @click="sortData(th.key)"
              style="cursor: pointer"
            >
              {{ th.name }}
              <template v-if="sort">
                <span class="material-symbols-outlined" v-if="th.sort">
                  swap_vert
                </span>
              </template>
            </th>
          </tr>
          <tr v-if="search">
            <th v-for="th in thead" :key="th">
              <template v-if="th.search">
                <SearchInput
                  :minw_auto="th.minw_auto"
                  :searchKey="th.key"
                  @search="onSearchInput"
                />
              </template>
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-if="filteredData.length > 0">
            <template
              v-for="(item, i) in filteredData.slice(
                this.currentPageStartIndex(),
                this.currentPageEndIndex()
              )"
              :key="i"
            >
              <tr>
                <template v-for="(cell, i) in thead" :key="i">
                  <td :class="cell.minw_auto ? 'minw-auto' : ''">
                    <slot :name="`cell-${cell.key}`" :row="item">
                      {{ item[prop] }}
                    </slot>
                  </td>
                </template>
              </tr>
            </template>
          </template>
          <template v-else>
            <tr>
              <td style="text-wrap: nowrap">Kayıt Bulunamadı.</td>
            </tr>
          </template>
        </tbody>
      </table>
      <div class="table-loader" v-if="tableLoader">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 -960 960 960"
          width="24"
        >
          <path
            d="M480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-155.5t86-127Q252-817 325-848.5T480-880q17 0 28.5 11.5T520-840q0 17-11.5 28.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160q133 0 226.5-93.5T800-480q0-17 11.5-28.5T840-520q17 0 28.5 11.5T880-480q0 82-31.5 155t-86 127.5q-54.5 54.5-127 86T480-80Z"
          />
        </svg>
      </div>
    </div>

    <div class="table-footer" v-if="filteredData.length > pageSize">
      <ul class="pagination">
        <li
          @click="changePage('prev')"
          :class="currentPage == 1 ? 'disable' : ''"
        >
          <span class="material-symbols-outlined"> chevron_left </span>
        </li>

        <li v-if="currentPage > 3 && pageCount > 5" @click="currentPage = 1">
          ...
        </li>
        <template v-for="page in pageCount" :key="page">
          <template v-if="currentPage + 2 >= page && currentPage - 2 <= page">
            <li
              :class="{ active: currentPage === page }"
              @click="goToPage(page)"
            >
              {{ page }}
            </li>
          </template>
        </template>
        <li
          v-if="currentPage <= pageCount - 3 && pageCount > 5"
          @click="currentPage = pageCount"
        >
          ...
        </li>

        <li
          @click="changePage('next')"
          :class="currentPage == pageCount ? 'disable' : ''"
        >
          <span class="material-symbols-outlined"> navigate_next </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import SearchInput from "./Search";
export default {
  name: "Table",
  components: {
    SearchInput,
  },
  data() {
    return {
      searchText: "",
      searchKey: "",
      sortByKey: null,
      sortDesc: false,
      prop: [],
      show_count: [10, 25, 50, 75],
      hide_scroll: true,
      currentPage: 1,
      pageSize: 10,
    };
  },
  // mounted() {
  //   this.checkTableWidth();
  //   window.addEventListener("resize", this.checkTableWidth);
  // },
  // beforeDestroy() {
  //   window.removeEventListener("resize", this.checkTableWidth);
  // },
  methods: {
    // checkTableWidth() {
    //   let container = document.querySelector(".table-container");
    //   let containerWidth = container.clientWidth;
    //     let tableWidth = document.querySelector(".table").clientWidth;
    //     tableWidth > containerWidth + 30
    //       ? (this.hide_scroll = true)
    //       : (this.hide_scroll = false);
    // },
    scrollTable(direction) {
      let container = document.querySelector(".table-container");
      direction == "left"
        ? (container.scrollLeft -= 100)
        : (container.scrollLeft += 100);
    },
    onSearchInput(data) {
      this.searchText = data.value;
      this.searchKey = data.key;
    },
    sortData(key) {
      if (this.sortByKey === key) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortByKey = key;
        this.sortDesc = false;
      }
    },
    goToPage(page) {
      this.currentPage = page;
    },
    currentPageStartIndex() {
      return (this.currentPage - 1) * this.pageSize;
    },

    // Mevcut sayfanın bitiş indisini hesaplayan computed property
    currentPageEndIndex() {
      return Math.min(this.currentPage * this.pageSize, this.data.length);
    },
    changePage(direction) {
      if (direction === "prev" && this.currentPage > 1) {
        this.currentPage--;
      } else if (direction === "next" && this.currentPage < this.pageCount) {
        this.currentPage++;
      }
    },
  },
  computed: {
    filteredData() {
      let filtered = this.data;

      const searchText = this.searchText.toLowerCase().trim();

      if (searchText) {
        filtered = filtered.filter((item) =>
          item[this.searchKey].toString().toLowerCase().includes(searchText)
        );
      }

      if (this.sortByKey) {
        filtered.sort((a, b) => {
          const aValue = a[this.sortByKey];
          const bValue = b[this.sortByKey];

          if (aValue < bValue) {
            return this.sortDesc ? 1 : -1;
          }
          if (aValue > bValue) {
            return this.sortDesc ? -1 : 1;
          }
          return 0;
        });
      }
      return filtered;
    },
    pageCount() {
      return Math.ceil(this.filteredData.length / this.pageSize);
    },
  },
  props: {
    data: Array,
    thead: Array,
    title: String,
    nohead: Boolean,
    document: Boolean,
    export: Boolean,
    search: Boolean,
    sort: Boolean,
    tableLoader: Boolean,
  },
};
</script>
<template>
  <div class="form-group" v-if="type == 'text'">
    <label :for="name">{{ label }}</label>
    <input
      type="text"
      class="form-control"
      :id="name"
      placeholder=""
      v-model="inputVal"
      :disabled="disabled"
    />    
  </div>
  <div class="form-group focus" v-if="type == 'text_focus'">
    <input
      type="text"
      class="form-control"
      :id="name"
      placeholder=""
      v-model="inputVal"
      :disabled="disabled"
    />
    <label :for="name">{{ label }}</label>
  </div>
  <div class="form-group" v-if="type == 'textarea'">
    <label :for="name">{{ label }}</label>
    <textarea
      :id="name"
      class="form-control"
      placeholder=""
      v-model="inputVal"
    ></textarea>
  </div>
  <div class="form-group focus long" v-if="type == 'textarea_focus'">
    <textarea
      :id="name"
      class="form-control"
      placeholder=""
      v-model="inputVal"
    ></textarea>
    <label :for="name">{{ label }}</label>
  </div>
  <div class="form-group" v-if="type == 'select'">
    <label :for="name">{{ label }}</label>
    <select :id="name" class="form-select" v-model="inputVal">
      <option disabled selected value="">{{ $t("select") }}</option>
      <option :value="option" v-for="option in options" :key="option">
        {{ option }}
      </option>
    </select>
  </div>
  <div class="form-group focus" v-if="type == 'select_focus'">
    <select :id="name" class="form-select" v-model="inputVal">
      <option disabled selected value="">{{ $t("select") }}</option>
      <option :value="option" v-for="option in options" :key="option">
        {{ option }}
      </option>
    </select>
    <label :for="name">{{ label }}</label>
  </div>
  <div class="form-group" v-if="type == 'selectID'">
    <label :for="name">{{ label }}</label>
    <select :id="name" class="form-select" v-model="inputVal">
      <option disabled selected value="">{{ $t("select") }}</option>
      <option :value="option.value" v-for="option in options" :key="option">
        {{ option.name }}
      </option>
    </select>    
  </div>
  <div class="form-group focus" v-if="type == 'selectIDFocus'">
    <select :id="name" class="form-select" v-model="inputVal">
      <option disabled selected value="">{{ $t("select") }}</option>
      <option :value="option.value" v-for="option in options" :key="option">
        {{ option.name }}
      </option>
    </select>
    <label :for="name">{{ label }}</label>
  </div>
  <div class="form-check radio-button" v-if="type == 'radio_button'">
    <input
      class="form-check-input"
      type="radio"
      :id="inputId"
      :name="name"
      :value="inputId"
      :disabled="disabled"
      v-model="inputVal"
    />
    <label class="form-check-label" :for="inputId">
      {{ label }}
    </label>
  </div>
</template>

<script>
export default {
  emits: ["inputVal"],
  props: {
    label: String,
    type: String,
    inputId: String,
    placeholder: String,
    value: String,
    name: String,
    options: Array,
    disabled: Boolean,
  },
  data() {
    return {
      inputVal: this.value,
    };
  },
  watch: {
    value(newValue) {
      this.inputVal = newValue;
    },
    inputVal(newInputVal) {
      this.$emit("inputVal", this.name, newInputVal);
    },
  },
};
</script>

import { createStore } from "vuex";

export default createStore({
  state: {
    breadcrumbs: {
      app: "",
      pages: []
    }
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
